import React from 'react';
import styles from './BodyHits.module.scss';

import BodyFront from './BodyFront/BodyFront';
import BodyBack from './BodyBack/BodyBack';
import BodyMissed from './BodyMissed/BodyMissed';

import { Hit } from '../../Models';

export interface HitData {
  position: number;
  zone: string;
  x: number;
  y: number;
}

export interface BodyHitsProps {
  hits: Hit[];
}

const renderHitDataRow = (
  side: string,
  hitData: HitData[]
): React.ReactElement => {
  return (
    <div className={styles.side}>
      <div className={styles.header}>{side}</div>
      <div className={styles.dataWrapper}>
        {hitData.map((data: HitData, index: number) => (
          <span className={styles.data} key={`hit-data-${index}`}>
            {data.zone !== 'MISSED'
              ? `${data.position}/${data.zone}`
              : `${data.position}`}
          </span>
        ))}
      </div>
      <hr />
    </div>
  );
};

const BodyHitsGraph = ({
  hits
}: BodyHitsProps): React.ReactElement<BodyHitsProps> => {
  let frontHitData: HitData[] = [];
  let missedHitData: HitData[] = [];
  let backHitData: HitData[] = [];

  hits.forEach((hit: Hit, index: number) => {
    switch (hit.side) {
      case 'front':
        frontHitData = [
          ...frontHitData,
          {
            position: index + 1,
            zone: hit.zone.toUpperCase(),
            x: hit.x,
            y: hit.y
          }
        ];
        break;
      case 'back':
        backHitData = [
          ...backHitData,
          {
            position: index + 1,
            zone: hit.zone.toUpperCase(),
            x: hit.x,
            y: hit.y
          }
        ];
        break;
      default:
        missedHitData = [
          ...missedHitData,
          {
            position: index + 1,
            zone: hit.zone.toUpperCase(),
            x: hit.x,
            y: hit.y
          }
        ];
        break;
    }
  });

  return (
    <div className={styles.bodyHits}>
      <div className={styles.hitsData}>
        {frontHitData.length > 0 && renderHitDataRow('Front', frontHitData)}
        {backHitData.length > 0 && renderHitDataRow('Back', backHitData)}
        {missedHitData.length > 0 && renderHitDataRow('Missed', missedHitData)}
      </div>

      <div className={styles.hitsGraph}>
        <BodyFront hits={frontHitData} />
        <BodyMissed hits={missedHitData} />
        <BodyBack hits={backHitData} />
      </div>
    </div>
  );
};

export default BodyHitsGraph;
