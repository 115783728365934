import React from 'react';
import styles from './RightPanel.module.scss';

import { Section, Field } from '../../../../Models';
import { convertFieldToString } from '../../../../Helpers';

import { SFDrawer, SFIconButton, SFScrollable } from 'sfui';

export interface RightPanelProps {
  isPanelOpen: boolean;
  isBigScreen: boolean;
  panelInformation: Section[];
  timezone?: string;
  closePanel: () => void;
}

const RightPanel = (
  props: RightPanelProps
): React.ReactElement<RightPanelProps> => {
  const fieldInformation = (field: Field, index: number): JSX.Element => {
    return (
      <div className={styles.fieldInformation} key={`${field}-${index}`}>
        <h4 className={styles.fieldInformationLabel}>{field.label}</h4>
        <p className={styles.fieldInformationValue}>
          {convertFieldToString(field, props.timezone)}
        </p>
      </div>
    );
  };

  const panelContent = (
    title: string,
    fields: Field[] | Field[][],
    index: number
  ): JSX.Element => {
    return (
      <div className={styles.panelContent} key={`${title}-${index}`}>
        <h2 className={styles.panelTitle}>{title}</h2>
        {fields.map((field: Field[] | Field, index: number) => {
          if (Array.isArray(field)) {
            field.map((field: Field) => {
              return fieldInformation(field, index);
            });
          }
          return fieldInformation(field as Field, index);
        })}
      </div>
    );
  };

  const getContent = () => {
    return (
      <SFScrollable
        className={styles.scrollable}
        containerClassName={styles.scrollableContainer}
      >
        {props.panelInformation.map(
          (contentInformation: Section, index: number) => {
            return panelContent(
              contentInformation.label,
              contentInformation.fields as Field[],
              index
            );
          }
        )}
      </SFScrollable>
    );
  };

  if (props.isBigScreen) {
    return <div className={styles.rightPanel}>{getContent()}</div>;
  }

  return (
    <SFDrawer
      open={props.isPanelOpen}
      anchor="right"
      PaperProps={{ className: styles.rightPanel }}
    >
      <SFIconButton
        sfIcon="Close"
        sfSize="medium"
        className={styles.panelCloseButton}
        onClick={() => props.closePanel()}
      />
      {getContent()}
    </SFDrawer>
  );
};

export default RightPanel;
