import { HitData } from '../BodyHits';

export interface BodyHitProps {
  hit: HitData;
  circleSize: number;
  fontSize: number;
  width: number;
  height: number;
}

const BodyHit = ({
  hit,
  circleSize,
  fontSize,
  width,
  height
}: BodyHitProps): React.ReactElement<BodyHitProps> => {
  return (
    <g
      transform={`translate(${(hit.x * width) / 100},${
        (hit.y * height) / 100
      })`}
    >
      <circle r={circleSize} />

      <text
        alignmentBaseline="middle"
        textAnchor="middle"
        fontSize={fontSize}
        fontFamily="Roboto"
        fill="white"
      >
        {hit.position}
      </text>
    </g>
  );
};

export default BodyHit;
