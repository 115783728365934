import React from 'react';
import styles from './Toolbar.module.scss';

import { Section } from '../../../Models';
import PdfService from '../../../Services/Pdf';
import { isMobile } from '../../../Helpers/index';

import {
  SFIconButton,
  SFSplitButton,
  SFButton,
  SFSplitButtonOption,
  useSFMediaQuery
} from 'sfui';
import RightPanel from './RightPanel/RightPanel';

import { BIG_MEDIA_SCREEN } from '../../../Constants/Media';

export interface ToolbarProps {
  extra: Section[];
  pdfServices: PdfService[];
  timezone?: string;
}

const Toolbar = (props: ToolbarProps): React.ReactElement<ToolbarProps> => {
  const isBigScreen: boolean = useSFMediaQuery(BIG_MEDIA_SCREEN);
  const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(isBigScreen);

  const splitButtonOptions = (): SFSplitButtonOption[] => {
    return [
      {
        label: 'Download as a PDF',
        onClick: (): void => {
          props.pdfServices.forEach((pdfService: PdfService) =>
            pdfService.downloadPdf()
          );
        }
      },
      {
        label: 'Print',
        onClick: (): void => {
          if (isMobile()) {
            window.print();
          } else {
            for (let i: number = props.pdfServices.length - 1; i >= 0; i--) {
              props.pdfServices[i].print();
            }
          }
        }
      }
    ];
  };

  return (
    <div className={styles.toolbar}>
      <h2 className={styles.toolbarTitle}>Viewer</h2>
      <div className={styles.toolbarButtons}>
        <SFIconButton
          sfIcon="Other"
          sfSize="medium"
          rotate="left"
          onClick={() => setIsPanelOpen(true)}
          className={styles.moreInformationIconButton}
        />
        <SFButton
          className={styles.moreInformationButton}
          variant="outlined"
          onClick={() => setIsPanelOpen(true)}
        >
          See more
        </SFButton>
        <SFSplitButton
          options={splitButtonOptions()}
          variant="contained"
          sfColor="blue"
          defaultSelected={0}
        />
      </div>
      <RightPanel
        isPanelOpen={isPanelOpen}
        isBigScreen={isBigScreen}
        closePanel={() => setIsPanelOpen(false)}
        panelInformation={props.extra}
        timezone={props.timezone}
      />
    </div>
  );
};

export default Toolbar;
