import React, { Fragment } from 'react';

import styles from './ReportBody.module.scss';

import { Section, Field, Attachment, BodyHits } from '../../../../Models/';
import { convertFieldToString } from '../../../../Helpers';

import BodyHitsGraph from '../../../../Components/BodyHits/BodyHits';
import { SFLink } from 'sfui';

export interface ReportBodyProps {
  reportBody: Section[];
  reportType: string;
  timezone?: string;
}

const renderComplexField = (field: Field, timezone?: string): JSX.Element => {
  switch (field.type) {
    case 'attachments':
      return (
        <div className={styles.contentAttributes}>
          {(field.value as Attachment[]).map(
            (attachment: Attachment, index: number) => {
              return (
                <div
                  className={styles.attachment}
                  key={`${attachment.name}-${index}`}
                >
                  {attachment.description && (
                    <Fragment>
                      <p className={styles.attachmentAtributeLabel}>
                        Description
                      </p>
                      <p
                        className={styles.attachmentAtributeValue}
                        dangerouslySetInnerHTML={{
                          __html: attachment.description
                            ? attachment.description.toString()
                            : ''
                        }}
                      ></p>
                    </Fragment>
                  )}

                  <p className={styles.attachmentAtributeLabel}>File</p>
                  {attachment.url !== null ? (
                    <SFLink
                      className={styles.attachmentLink}
                      sfSize="medium"
                      color="primary"
                      href={attachment.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {attachment.name}
                    </SFLink>
                  ) : (
                    <p className={styles.attachmentAtributeValue}>
                      {attachment.name}
                    </p>
                  )}
                </div>
              );
            }
          )}
        </div>
      );

    case 'bodyhits':
      return (
        <div className={styles.contentAttributes}>
          <BodyHitsGraph hits={field.value as BodyHits} />
        </div>
      );
    default:
      return (
        <p
          className={styles.contentAttributes}
          dangerouslySetInnerHTML={{
            __html: convertFieldToString(field, timezone)
          }}
        ></p>
      );
  }
};

const renderFieldContent = (
  contentTitle: string,
  field: Field,
  index: number,
  timezone?: string
): JSX.Element => {
  return (
    <div className={styles.fieldContent} key={`${field.label}-${index}`}>
      <h4 className={styles.contentTitle}>{contentTitle}</h4>
      {renderComplexField(field, timezone)}
    </div>
  );
};

const renderField = (
  field: Field | Field[],
  index: number,
  timezone?: string
): JSX.Element | undefined => {
  if (Array.isArray(field)) {
    return (
      <div className={styles.fieldContentArray} key={`field-${index}`}>
        {field.map((section: Field, indexSection: number) => {
          if (section.value === null || undefined) {
            return undefined;
          }
          return renderFieldContent(
            section.label,
            section,
            indexSection,
            timezone
          );
        })}
      </div>
    );
  } else {
    if (field.value === null || undefined) {
      return undefined;
    }
    return renderFieldContent(field.label, field, index, timezone);
  }
};

const renderBodySection = (
  bodySection: Section,
  timezone?: string
): JSX.Element => {
  return (
    <div className={styles.bodySection} key={`field-${bodySection.label}`}>
      <h3 className={styles.sectionTitle}>{bodySection.label}</h3>
      {bodySection.fields.map((field: Field[] | Field, index: number) => {
        return renderField(field, index, timezone);
      })}
    </div>
  );
};

const Body = (props: ReportBodyProps): React.ReactElement<ReportBodyProps> => {
  return (
    <div className={styles.reportBody}>
      <h2 className={styles.bodyTitle}>{`${props.reportType} Report`}</h2>
      {props.reportBody.map((bodySection) => {
        return renderBodySection(bodySection, props.timezone);
      })}
    </div>
  );
};

export default Body;
