import React, { useState } from 'react';
import { useLocation } from 'react-router';
import styles from './SFNavPanel.module.scss';
import { SFDrawer, SFIconButton } from 'sfui';
import SFNavContent from './SFNavContent/SFNavContent';
import { SFNavLink } from './SFNavLink';
import { Customer } from '../../Models';

export interface SFNavPanelProps {
  className?: string;
  customer: Customer;
  links: SFNavLink[];
  isBigScreen: boolean;
  isOpen: boolean;
  onLinkSelected: (link: SFNavLink) => void;
  onClose?: () => void;
}

const SFNavPanel = ({
  className,
  customer,
  links,
  isBigScreen,
  isOpen,
  onLinkSelected,
  onClose
}: SFNavPanelProps): React.ReactElement<SFNavPanelProps> => {
  const location = useLocation();
  const [linkSelected, setLinkSelected] = useState<SFNavLink>(links[0]);

  const onDrawerClose = () => {
    onClose && onClose();
  };

  const onLinkChange = (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: SFNavLink
  ) => {
    onDrawerClose();
    if (location.pathname === link.to) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setLinkSelected(link);
      onLinkSelected(link);
    }
  };

  return (
    <div className={`${styles.sfNavPanel} ${className || ''}`}>
      {isBigScreen && (
        <div className={styles.content}>
          <SFNavContent
            customer={customer}
            links={links}
            isPanel={false}
            selected={linkSelected}
            onLinkChange={onLinkChange}
          />
        </div>
      )}

      {!isBigScreen && (
        <div className={styles.panelWrapper}>
          <SFDrawer
            className={styles.navPanelDrawer}
            open={isOpen}
            PaperProps={{
              className: styles.navPanelDrawerPaper
            }}
            onClose={() => onDrawerClose}
          >
            <div className={styles.topBar}>
              <div className={styles.buttons}>
                <SFIconButton
                  sfIcon="Close"
                  sfSize="medium"
                  onClick={onDrawerClose}
                />
              </div>
            </div>

            <SFNavContent
              customer={customer}
              links={links}
              isPanel={true}
              selected={linkSelected}
              onLinkChange={onLinkChange}
            />
          </SFDrawer>
        </div>
      )}
    </div>
  );
};

export default SFNavPanel;
