import React from 'react';

import styles from './ReportHeader.module.scss';

import { ReportHeader } from '../../../../Models';

export interface ReportHeaderProps {
  reportHeader: ReportHeader;
  type: string;
}

const Header = (
  props: ReportHeaderProps
): React.ReactElement<ReportHeaderProps> => {
  return (
    <div className={styles.reportHeader}>
      <div className={styles.unclassified}>
        <h3>{props.reportHeader.unclassified}</h3>
      </div>
      <div className={styles.headerInformation}>
        <div className={styles.customerInformation}>
          <h5 className={styles.customerName}>
            {props.reportHeader.customer.full_name}
          </h5>
          <p className={styles.customerAddress}>
            {props.reportHeader.customer.address}
          </p>
        </div>

        <div
          className={`${styles.customerInformation} ${styles.alignInformation}`}
        >
          <p>
            <label
              className={styles.customerInfoLabel}
            >{`${props.type} `}</label>
            <strong>{props.reportHeader.report_id}</strong>
          </p>
          <p>
            <label className={styles.customerInfoLabel}>
              {'Employee Completing Report '}
            </label>
            <strong>{props.reportHeader['author.name']}</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
