import React from 'react';
import styles from './Loader.module.scss';
import { SFSpinner } from 'sfui';

const Loader = (): React.ReactElement<{}> => {
  return (
    <div className={styles.loader}>
      <SFSpinner size={40} />
    </div>
  );
};

export default Loader;
