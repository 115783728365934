import React, { useState } from 'react';
import styles from './SFLogo.module.scss';
import { ReactComponent as Badge } from '../../Images/ui_smartforce_badge.svg';
import { Customer } from '../../Models';

export interface SFLogoProps {
  className?: string;
  customer: Customer;
}

const SFLogo = ({
  className,
  customer
}: SFLogoProps): React.ReactElement<SFLogoProps> => {
  const [errorBadge, setErrorBadge] = useState<boolean>(false);

  const onErrorBadge = () => {
    setErrorBadge(true);
  };

  return (
    <div className={`${styles.sfNavLogo} ${className || ''}`}>
      <div className={styles.imgContainer}>
        {customer.badge && !errorBadge && (
          <img
            className={styles.badge}
            src={customer.badge}
            alt=""
            onError={onErrorBadge}
          />
        )}

        {(!customer.badge || errorBadge) && <Badge className={styles.badge} />}
      </div>

      <div className={styles.customerInfo}>
        <h3 className={styles.name}>{customer.short_name}</h3>
        <h5 className={styles.region}>{customer.region}</h5>
      </div>
    </div>
  );
};

export default SFLogo;
