import React from 'react';

import styles from './ReportFooter.module.scss';

import { ReportFooter } from '../../../../Models/';

export interface ReportFooterProps {
  footer: ReportFooter;
}

const Footer = (
  props: ReportFooterProps
): React.ReactElement<ReportFooterProps> => {
  return (
    <div className={styles.reportFooter}>
      <div className={styles.unclassified}>
        <h5>{props.footer.unclassified}</h5>
      </div>
    </div>
  );
};

export default Footer;
