import React from 'react';
import styles from './SFNavContentLink.module.scss';
import { Link } from 'react-router-dom';
import { SFIcon } from 'sfui';
import { SFNavLink } from '../../SFNavLink';

export interface SFNavContentLinkProps {
  link: SFNavLink;
  selected?: boolean;
  external?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const SFNavContentLink = ({
  link,
  selected,
  external = false,
  onClick
}: SFNavContentLinkProps): React.ReactElement<SFNavContentLinkProps> => {
  const getContent = () => (
    <React.Fragment>
      <SFIcon icon={link.icon as string} className={styles.icon} size={20} />
      <span className={styles.text}>{link.label}</span>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!external && (
        <Link
          to={link.to}
          className={`${styles.link} ${selected ? styles.isSelected : ''}`}
          onClick={onClick}
        >
          {getContent()}
        </Link>
      )}

      {external && (
        <a
          href={link.to}
          className={styles.link}
          onClick={onClick}
          target="_blank"
          rel="noreferrer"
        >
          {getContent()}
        </a>
      )}
    </React.Fragment>
  );
};

export default SFNavContentLink;
