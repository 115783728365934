import React from 'react';
import styles from './BodyBack.module.scss';
import { HitData } from '../BodyHits';
import BodyHit from '../BodyHit/BodyHit';

export interface BodyBackProps {
  hits: HitData[];
}

const SVG_WIDTH = 427.2;
const SVG_HEIGHT = 841.8;

const BodyBack = (props: BodyBackProps): React.ReactElement<BodyBackProps> => {
  return (
    <div className={styles.bodyBack}>
      <div className={styles.bodyContainer}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 427.2 841.8"
          enableBackground="new 0 0 427.2 841.8;"
          className={styles.svgGraph}
          xmlSpace="preserve"
        >
          <g id="Layer_x0020_1">
            <g id="_2537566457712">
              <path
                id="g"
                className={styles.st0}
                d="M160,145.5c0.7-0.2,1.4-0.5,2.2-0.8c7.5,4.1,23.9,10.9,48.6,10.9c28.6,0,45.8-8.9,51.3-12.5
				c1.8,0.7,3.1,1.4,4.8,1.9c3.1,1.2,5.8,2.1,9,2.9c3.9,1.2,7.7,1.8,11.1,2.7c-8.4,44.6-0.3,84,0.8,89.1c-1,7.7-4.2,26.1-4.6,29.5
				h-71.4H208h-65.8c-0.2-1.7-4.4-28.9-4.4-28.9s8.2-40.9-0.9-88.4c4.3-1,8.7-2.2,13.8-3.6C153.9,147.9,157.1,146.9,160,145.5z"
              />
              <path
                id="h"
                className={styles.st0}
                d="M141.1,355.4L141.1,355.4c0.2-0.5,7-28.3,7.7-41.9c0.3-10.4-0.8-19.1-3.8-30.9
				c-0.3-1.2-0.5-2.5-0.8-3.8c-0.2-1.2-0.5-2-0.7-3.2c-0.3-2.2-0.8-4.4-1.2-6.1H208h3.8h71.4c-0.2,0-0.2,0.9-0.3,1.5
				c-0.8,5.1-2.2,9.9-3.4,14.8c-2.1,7.8-4.1,16-4.6,24.2c-0.2,3.1,0,6.1,0.5,9.2c1.7,10.2,5.3,19.9,7.8,30c1.7,8.7,3.2,17.2,4.4,24
				H138.3c0.2-1.7,0.5-2.9,0.7-4.4C139,367.5,140.7,356.9,141.1,355.4z"
              />
              <path
                id="f"
                className={styles.st0}
                d="M319,332.1c-1.7-3.7-3.6-7.5-5.3-11.1c-3.5-7-7.3-14.1-8.7-22.2l0,0l35.2-13.4
				c0.7,1.7,1.5,3.2,2.7,4.6c1.7,2.2,3.4,4.4,4.9,6.6c14.1,20.4,20.2,44.8,31.5,66.3c1.2,2.7,2.2,5.6,3.2,8.4
				c2.2,5.6,4.2,11.1,6.6,16.7c1.7,4.1,4.2,7.3,9,8.7c1.7,0.5,2.9,1.9,4.3,3.1c6.3,6.2,12.1,12.5,15.7,20.3c1.2,2.7,3.8,4.6,6,6.5
				c1.2,0.9,1.4,1.5,0.8,2.2c0.3,0.5,0.5,0.8,0.3,1.2c-0.2,0.7-0.9,1-2.1,1.5c-1,0.3-1.9,0.5-2.7,0.5c-3.2,0-6.3-2.1-9.6-6.1
				l-0.1-0.2c-1.9-2.4-3.6-4.8-7.5-5.6c0.3,1.2,0.5,2.6,0.7,4.3c0.8,5.3,1.7,13.6,2.4,22c0.7,7.2,1.4,14,1.9,17.9
				c0.3,2.2-0.2,3.9-1.4,5.1v0.2l-0.5,0.2l0,0c-2.9,0.2-3.6-1.9-3.9-3.9c-1.4-9.6-5.6-28.5-8-33.1c1.7,7.8,3.4,15.8,5.1,23.7
				c0.7,3.4,1.4,6.8,2.1,10.2c0,0.3,0.2,0.9,0.2,1.4v0.2c0.3,1.7,0.9,5.1-2.2,5.6c-0.3,0-0.5,0.2-0.8,0.2c-1.9,0-2.1-1.7-2.2-3.2
				c0-0.5,0-0.9-0.2-1.2c-2.4-10.1-5.6-20.1-8.7-30c-0.3-1.4-0.9-2.7-1.2-4.1c0-0.2,0-0.2,0-0.3c-0.2-0.3-0.2-0.7-0.7-0.8
				c0,0.2,0,0.3,0,0.5c1.2,6.3,5.6,22.3,7.5,29.6c0.3,1,0.9,2.2,0.9,3.1c0.2,0.8,0.3,1.5,0,2.2c-0.2,0.5-0.7,0.9-1.5,1.2
				c-0.3,0.2-0.7,0.2-1,0.2c-1.5,0-2.2-1.5-2.5-2.7c-0.2-0.3-0.3-0.7-0.3-0.8c-1.9-3.8-4.2-10.6-6.3-16.5c-1.5-4.6-3.2-9.2-4.5-11.8
				c1.6,5.4,3.1,10.7,4.6,16.2c0.3,1,0.7,2.1,1,3.1c0.2,0.7,0.3,1.2,0.3,1.5c-0.2,0.5-0.5,0.7-1.2,1c-0.3,0.2-0.5,0.2-0.9,0.2
				c-1.4,0-2.4-1.5-3.1-2.7c-1.4-2.5-10.9-26.8-13.3-36.8c-1-4.1-0.5-8.2,0-12.2v-0.5c0.7-6.1-2.4-10.8-5.6-15.7
				C355.9,386.4,320.1,334.8,319,332.1z"
              />
              <path
                id="e"
                className={styles.st0}
                d="M304.8,298.8c-0.3-2.2-3.9-11.8-5.1-15.1c-4.9-12.1-6.6-22.7-9.4-33.6c-0.5-2.1-1-4.1-1.4-6
				c-0.3-1.8-0.8-4.9-0.8-4.6c-1.2-5.1-9-43.9-0.9-88.2c9.6,2.4,17.6,5.1,26.4,13.6c6.3,6.2,10.2,14.2,11.4,22
				c1,6.5,10.2,59.3,11.9,70.7c1.2,8.2,2.4,16.2,2.7,24.4c0,0.3,0,0.8,0,1.2c0.2,0.7,0.3,1.5,0.7,2.2L304.8,298.8z"
              />
              <path
                id="d"
                className={styles.st0}
                d="M45.2,460.6c-0.3,0-0.5,0-0.9-0.2c-0.7-0.2-1-0.5-1.2-1c0-0.3,0-1,0.3-1.5c0.7-1.5,0.7-2,1-3.1
				c1.5-5.5,3.1-10.7,4.6-16.2c-1.2,2.5-2.7,7.2-4.4,11.8c-2.1,5.9-4.4,12.8-6.3,16.5c-0.2,0.3-0.3,0.5-0.3,0.8
				c-0.5,1.2-1,2.7-2.5,2.7c-0.3,0-0.7,0-1-0.2c-0.7-0.2-1.2-0.7-1.5-1.2c-0.3-0.7-0.2-1.5,0-2.2c-0.2-1,0.5-2.2,0.9-3.1
				c2-7.2,6.3-23.3,7.5-29.6c0-0.2,0-0.3,0-0.5c-0.5,0.2-0.7,0.5-0.7,0.8c0,0.2,0,0.2,0,0.3c-0.3,1.4-0.8,2.7-1.2,4.1
				c-3.1,9.7-6.3,19.8-8.7,30c0,0.3-0.1,0.7-0.1,1.2c-0.2,1.4-0.3,3.2-2.2,3.2c-0.2,0-0.5,0-0.9-0.2c-3.1-0.7-2.5-4.1-2.2-5.6v-0.2
				c0-0.5,0.2-0.8,0.2-1.4c0.7-3.4,1.4-6.8,2-10.2c1.7-7.8,3.4-15.8,5.1-23.7c-2.4,4.8-6.6,23.7-8,33.1c-0.3,2.1-1,4.1-3.9,3.9l0,0
				l-0.5-0.1v-0.2c-1.4-1.2-1.7-2.9-1.4-5.1c0.7-3.9,1.2-10.6,1.9-17.9c0.8-8.2,1.5-16.7,2.4-22c0.2-1.7,0.5-3.1,0.7-4.3
				c-3.9,0.9-5.6,3.2-7.5,5.6l-0.2,0.2c-3.1,4.1-6.1,6.1-9.5,6.1c-0.8,0-1.9-0.2-2.7-0.5c-1.4-0.5-2.1-0.9-2.1-1.6
				c0-0.3,0-0.8,0.3-1.2c-0.3-0.5-0.2-1.2,0.8-2.2c2.2-1.8,4.8-3.7,6-6.5c3.7-7.8,9.5-14.1,15.8-20.2c1.2-1.2,2.4-2.4,4.2-3.1
				c4.6-1.4,7.3-4.6,9-8.7c2.2-5.5,4.4-11.1,6.6-16.7c1-2.7,2.2-5.6,3.2-8.4c11.4-21.5,17.4-45.8,31.5-66.3c1.5-2.2,3.2-4.1,4.9-6.1
				c1-1.4,2.1-3.9,2.5-3.9l0,0l36.3,12.6l0,0c-1.7,7.9-6.6,14.7-9.2,22c-2.4,7-7.8,19.8-12.6,26.6c-13.3,18.9-32.2,41.4-34.8,45.3
				c-3.2,4.9-6,9.4-5.3,15.5v0.5c0.5,3.9,0.8,8.2,0,12.3c-2.4,9.9-11.9,34.1-13.3,36.8C47.5,459.2,46.5,460.6,45.2,460.6z"
              />
              <path
                id="c"
                className={styles.st0}
                d="M87.7,283.2c0-0.3,0-0.9,0-1.2c0.2-8.2,2.7-16.2,4.1-24.4c1.7-11.4,5.6-22.8,8.4-34.6
				c0-0.2,0.2-0.5,0.2-0.7c0.3-2.6,4.3-29.2,5.3-35.6c1.2-8,3.6-15.5,9.2-21.8c7.3-8.4,14.5-11.1,22.8-13.3c0,0,0,0-0.2,0
				c9,47.7,0.8,88.6,0.8,88.6l-0.3,4.6c-0.3,1.7-0.9,3.6-1.2,5.3c-2.9,10.9-5.4,20.3-10.4,32.2c-1.2,3.4-3.6,14.5-3.9,16.7
				l-35.3-13.5C87.2,284.7,87.6,284,87.7,283.2z"
              />
              <path
                id="b"
                className={styles.st0}
                d="M262.1,143.3c-0.2,0-17.7-8.7-20.6-13.4c-3.6-6-3.2-17.6-1.7-24.2c0.3-2.9,10.2-29.6,13.6-39.5
				c-7.2,15.3-22.8,26.1-40.9,26.1c-18.1,0-33.7-10.7-40.9-26.1c3.4,10.1,14.2,41.2,14.5,42.1c1.4,4.9,0.7,14.8-0.2,17.5
				c-0.2,0.5-0.5,1-0.9,1.5c-3.6,6.3-9,10.2-14.6,13.3c0,0-5.5,3.1-8,4.2c7.3,3.9,23.5,10.9,48.5,10.9c23.7,0,39.4-6,47.2-10.1
				C258,145.5,261.2,143.8,262.1,143.3z"
              />
              <path
                id="a"
                className={styles.st0}
                d="M212.5,1.7c12.4,0,23.8,5.1,32,13.3c8.2,8.2,13.3,19.4,13.3,32c0,12.6-5.1,23.8-13.3,32
				c-8.2,8.2-19.4,13.3-32,13.3c-12.6,0-23.9-5.1-32-13.3s-13.3-19.4-13.3-32c0-12.6,5.1-23.9,13.3-32S200,1.7,212.5,1.7z"
              />
              <path
                id="j"
                className={styles.st0}
                d="M146.9,652.2c-0.5,7.3-0.7,14.5-0.3,20.6c0.7,12.9,3.2,32.4,7.9,46.2c0.3,1.4,0.7,2.9,1,4.3
				c0.3,1.5,0.7,2.9,1,4.4c0,0,9,42.6,10.1,48.4c0.5,2.9,0.9,5.8,1.2,8.7c2.2,11.2-0.5,23.8,5.9,34.1c-2.9-0.8-1.9-7.5-4.4-8.5
				c-1.7,1.9-10.8,3.6-12.3,3.4c-4.9,0.3-9,0.2-13.8,1c-1.8,0.5-4.2,3.2-2.9,5.8c0.2,0.3,1,0.3,1,0.5c1.8,4.2,5.9,4.8,9.7,6.5
				c7.1,3.4,15,4.8,22.7,8c2.2,1,5.8,2.1,8,3.1c4.6,2.4,9.4-0.2,12.9-3.6c3.2-3.2,2.1-7,0.2-10.5c-0.5-0.9-0.8-1.7-1.2-2.6
				c-0.3-0.8-0.7-1.7-1-2.7c-0.5-2.7-3.2-6.8-0.3-8.7c0.3,3.2,2.2,10.9,2.9,14c-0.2-5.8,2.4-11.8,2.7-15.5c0.5-4.4-2.9-8-3.1-12.1
				c-0.3-11.8-0.2-23.5-0.7-35.2c0-1.4,0-2.7,0-4.3c0-7.3,5.4-58.6,6.1-64.9c0.7-6.3,3.8-24.7-2.7-46.2c-0.5-2.2-1.2-4.2-1.9-6.5
				c-0.3-1.2-0.7-2.5-1-4.2h-45.6C147.9,640.8,147.2,646.4,146.9,652.2z"
              />
              <path
                id="l"
                className={styles.st0}
                d="M229,642.8c-1,3.9-2.2,8.5-3.1,13.3c-1,5.3-1.5,10.8-1.2,15.3c0.9,10.8,2.4,27.6,3.8,38
				c1.4,9.7,2.7,19.6,3.6,29.5c0,1.2,0,2.2,0,3.4c1.2,20.3,3.1,40.9-2.9,60.7c-0.3,1.4-0.8,2.7-1.4,4.1c-0.2,0.9-0.2,1.7,0,2.6
				c0.3,3.4,2.7,9.2,4.3,12.4c0.3-1.5,0.3-5.5,0.8-6.8s0.9-2.9,1.4-4.2c0.2,1.2,0.3,2.4,0.3,3.4c-0.2,5.6-3.2,10.5-4.6,16.2
				c-0.2,1.2,0,2.2,0.3,3.1c0.3,0.9,0.8,1.7,1.7,2.6c0.8,0.8,1.9,1.5,3.2,2.2c3.9,2.1,7.8,2.1,12.1,0.3c11.4-4.2,20.1-4.9,29.7-8.2
				c2.4-0.9,4.6-3.2,6.1-5.6c4.8-5.6,1.4-8.8,0.5-9.4c-4.4-2.2-10.7-0.7-16.5-0.2c-1.2,0-2.2,0.2-3.4,0c-2.4,0.3-4.6-2.2-6.3-1.5
				c-2.4,1-2.4,1.4-1.9-1.4c0.3-1.5,0.2-3.1,0.2-4.6c1.5-11.3,3.1-23.3,5.3-33.9c5.6-34.6,30.9-88.4,14.8-137c0-0.5-0.2-1.4-0.3-1.4
				h-45.1C230,637.4,229.5,641.3,229,642.8z"
              />
              <path
                id="k"
                className={styles.st0}
                d="M212.5,404.2v-30.7h75.3c3.9,23.5,5.5,39.4,5.6,40.7c3.4,33.5,4.4,68.5-3.1,101.7
				c-6.6,29.5-22,57.9-18.9,89.1c0.2,2.7,0.3,5.6,0.5,8.4c0.2,2.2,0.5,4.5,0.7,6.8c1.4,4.6,2.4,9.7,3.2,15h-45.1
				c0.3-1.5,0.5-2.9,0.8-3.8c0.5-1.5,1-3.1,1.2-4.8c0.5-3.6,0.2-7-0.7-10.5c-4.6-18.9-6.6-37-6-54.9c0.7-17.2-5.4-56.7-5.3-64.1
				c0-0.5-1-23.2-1.7-33.7c-0.3-4.3-0.7-11.9-6.8-13.3l0,0L212.5,404.2L212.5,404.2z"
              />
              <path
                id="i"
                className={styles.st0}
                d="M131,429c0.2-1.9,0.3-3.6,0.5-5.5c0.9-8.5,2.1-16.9,3.1-25.4c0.8-8.5,3.7-24.5,3.7-24.4h74v30.3
				c0,0-0.2,46,0,46c-0.2,0-4.6,1.4-6,5.1c-1.2,3.4-1.5,13.8-2,20.9c-0.3,4.8-5.6,75-5.5,88.3v1.5c0.3,15.8-1.5,32-5.8,48
				c-1,3.8-1.2,7.5-0.5,11.8c0.2,1,0.3,2.2,0.5,3.6c0.3,1.8,0.7,3.9,1,5.9h-45.3c0.7-4.1,1.7-8,2.7-11.6c0.3-1.7,0.7-3.4,1-4.9
				c0.2-0.9,0.3-1.9,0.3-2.9c0.8-7.5,1.2-15,0.8-23.3c-0.2-2.6-0.2-5.1-0.3-7.7c-0.5-8.8-2-16.7-4.8-23.7
				c-6.5-17.6-14.3-55.7-14.6-57.6C128.6,479.1,130.2,453.9,131,429z"
              />
              <path
                id="letras"
                className={styles.st1}
                d="M220.5,55.5h-2.9l-1.5-4.1h-6.5l-1.4,4.1h-2.9l5.9-16.4h3.1L220.5,55.5z M354.8,340.1h-6.2v4.9
				h5.6v2.2h-5.6v6.8h-2.7v-16.4h8.9V340.1z M313.8,240.1h-9.2v-16.4h8.8v2.4h-6.1v4.6h5.6v2.2h-5.6v4.8h6.5V240.1L313.8,240.1z
				M69,354.2v-16.4h4.8c6,0,9,2.7,9,8c0,2.5-0.9,4.6-2.6,6.1c-1.7,1.5-3.9,2.4-6.8,2.4H69V354.2z M71.6,340.1v11.8h2.2
				c1.9,0,3.4-0.5,4.5-1.5c1-1,1.5-2.5,1.5-4.4c0-3.8-2.1-5.8-6-5.8H71.6z M123.2,239.4c-1.2,0.7-2.7,1-4.6,1c-2.4,0-4.2-0.7-5.8-2.2
				c-1.4-1.5-2.2-3.4-2.2-5.9c0-2.6,0.9-4.8,2.4-6.3c1.5-1.5,3.6-2.4,6.1-2.4c1.5,0,2.9,0.2,3.9,0.7v2.7c-1-0.7-2.4-1-3.6-1
				c-1.7,0-3.2,0.5-4.3,1.7c-1,1.2-1.7,2.7-1.7,4.6c0,1.9,0.5,3.2,1.5,4.2c1,1,2.4,1.5,4.1,1.5c1.5,0,2.9-0.3,4.1-1V239.4z
				M257.1,701.5h-9v-16.4h2.7v14.1h6.3V701.5L257.1,701.5z M174.8,695.7c0,1.9-0.5,3.4-1.4,4.6c-0.8,1.2-2.2,1.5-3.9,1.5
				c-0.7,0-1.4-0.2-1.9-0.3v-2.5c0.5,0.3,1,0.5,1.7,0.5c1.8,0,2.7-1.4,2.7-4.2v-10.1h2.7V695.7L174.8,695.7z M265,514.6h-3.6
				l-5.6-7.5c-0.1-0.3-0.3-0.5-0.3-0.5l0,0v8h-2.7v-16.4h2.7v7.7l0,0c0.1-0.2,0.1-0.3,0.3-0.5l5.5-7.2h3.2L258,506L265,514.6z
				M164.7,514.6h-2.9v-16.4h2.7v16.4H164.7z M219.3,333.3h-2.7v-7.2h-7.7v7.2h-2.7v-16.4h2.7v6.8h7.7v-6.8h2.7V333.3z M219.1,221.3
				c-1.7,0.9-3.6,1.4-5.6,1.4c-2.4,0-4.4-0.7-5.9-2.2c-1.6-1.5-2.2-3.6-2.2-6c0-2.5,0.8-4.6,2.4-6.3c1.7-1.7,3.8-2.4,6.3-2.4
				c1.8,0,3.4,0.3,4.6,0.8v2.7c-1.2-0.8-2.7-1.2-4.4-1.2s-3.3,0.5-4.3,1.7c-1.2,1.2-1.7,2.6-1.7,4.4c0,1.9,0.5,3.4,1.4,4.5
				c1,1,2.2,1.5,3.9,1.5c1.2,0,2.1-0.2,2.9-0.7v-3.9H213v-2.2h6.2V221.3L219.1,221.3z M208.4,145.9v-16.4h5.3c1.5,0,2.9,0.3,3.8,1
				c0.8,0.7,1.4,1.5,1.4,2.7c0,0.9-0.3,1.7-0.9,2.4c-0.5,0.7-1.2,1.2-2.2,1.5l0,0c1.2,0.2,2,0.5,2.7,1.2c0.7,0.7,1,1.7,1,2.7
				c0,1.4-0.5,2.5-1.7,3.4c-1,0.8-2.5,1.4-4.2,1.4H208.4z M211.1,131.5v4.8h1.7c1,0,1.7-0.2,2.2-0.7c0.5-0.5,0.8-1,0.8-1.9
				c0-1.4-1-2.1-2.7-2.1L211.1,131.5L211.1,131.5z M211.1,138.3v5.1h2.4c1,0,1.9-0.2,2.4-0.7c0.5-0.5,0.9-1.2,0.9-1.9
				c0-1.7-1.2-2.6-3.6-2.6H211.1L211.1,138.3z M215.2,49.1l-2.2-6.5c0-0.2-0.2-0.5-0.2-1l0,0c0,0.5-0.2,0.8-0.2,1l-2.2,6.5H215.2z"
              />
            </g>
          </g>

          {props.hits.map((hit: HitData) => (
            <BodyHit
              key={`hit-${hit.position}`}
              hit={hit}
              circleSize={23}
              fontSize={23}
              width={SVG_WIDTH}
              height={SVG_HEIGHT}
            />
          ))}
        </svg>
      </div>
    </div>
  );
};

export default BodyBack;
