import React from 'react';
import { SFPaper } from 'sfui';
import Header from './ReportHeader/ReportHeader';
import Body from './ReportBody/ReportBody';
import Footer from './ReportFooter/ReportFooter';

import { Report } from '../../../Models/';

import styles from './ReportRender.module.scss';

export interface ReportRenderProps {
  report: Report;
  timezone?: string;
}

const ReportRender = (
  props: ReportRenderProps
): React.ReactElement<ReportRenderProps> => {
  return (
    <SFPaper elevation={6} className={styles.reportRender}>
      <div className={styles.report}>
        <Header reportHeader={props.report.header} type={props.report.type} />
        <Body
          reportBody={props.report.body}
          reportType={props.report.type}
          timezone={props.timezone}
        />
        <Footer footer={props.report.footer} />
      </div>
    </SFPaper>
  );
};

export default ReportRender;
