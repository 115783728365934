import { Field } from '../Models';

function formatISOString(
  field: string,
  isWithTime: boolean,
  timeZone?: string
): string {
  const formatter = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone
  });

  const formattedDate = formatter.format(new Date(field));
  const formattedDateParts = formattedDate.split(', ');
  const weekday = formattedDateParts[0];
  const [month, day] = formattedDateParts[1].split(' ');

  let year, hour, minute;

  if (formattedDate.indexOf('at') === -1) {
    year = formattedDateParts[2];
    [hour, minute] = formattedDateParts[3].split(':');
  } else {
    year = formattedDateParts[2].substring(0, 4);
    [hour, minute] = formattedDateParts[2].substring(8).split(':');
  }

  let dateString: string = `${weekday} ${month} ${day} ${year}`;

  if (isWithTime) {
    dateString += ` - ${hour === '24' ? '00' : hour}:${minute}`;
  }

  return dateString;
}

function getFormatDateUTC(isoString: string): string {
  if (!isoString) return '';

  const [year, month, day] = isoString.substring(0, 10).split('-');
  return new Date(+year, +month - 1, +day).toDateString();
}

export function convertFieldToString(field: Field, timezone?: string): string {
  switch (field.type) {
    case 'boolean':
      return field.value === true ? 'Yes' : 'No';
    case 'date':
      return formatISOString(field.value as string, false, timezone);
    case 'datetime':
      return formatISOString(field.value as string, true, timezone);
    case 'dateutc':
      return getFormatDateUTC(field.value as string);
    default:
      return field.value !== undefined && field.value !== null
        ? field.value.toString()
        : '';
  }
}

export function isSafari(): boolean {
  let ua = window.navigator.userAgent;
  if (!window || !window.navigator || !ua) {
    return false;
  }
  return ua.search('Safari') !== -1 && ua.search('Chrome') === -1;
}

export const isFieldEmpty = (field: Field) =>
  field.value === null ||
  field.value === undefined ||
  field.value.toString().trim().length === 0;

export const upperFirstChar = (text: string) => {
  return `${text[0].toUpperCase()}${text.substring(1, text.length)}`;
};

export const cloneObject = (obj: any) => JSON.parse(JSON.stringify(obj));

function legacyIsMobile() {
  //Must be Ipad OS
  if (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    return true;
  } else {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
}

export const isMobile = () => {
  const uaData = (window.navigator as any).userAgentData;
  return uaData ? uaData.mobile : legacyIsMobile();
};

export const isRichText = (value: string) => {
  return /^<div class="ExternalClass.+">/.test(value);
};

export const removeHTMLTags = (htmlString: string): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;

  //Replace &nbsp; with whitespace
  return div.textContent?.replace(/\xA0/g, ' ') as string;
};

export const removeEmptyTags = (htmlString: string): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  removeEmptyNodes(div);
  return div.innerHTML;
};

function removeEmptyNodes(node: ChildNode) {
  if (node.childNodes.length === 0) {
    if (node.nodeName !== 'BR' && node.textContent?.trim().length === 0) {
      node.parentElement?.removeChild(node);
    }
  } else {
    for (let i = 0; i < node.childNodes.length; i++) {
      removeEmptyNodes(node.childNodes[i]);
    }

    if (node.childNodes.length === 0) {
      node.parentElement?.removeChild(node);
    }
  }
}

export function isValidTimeZone(timeZone: string): boolean {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch (ex) {
    return false;
  }
}
