import React from 'react';
import styles from './ErrorPage.module.scss';
import { SFPaper, SFIcon, SFLink } from 'sfui';

export interface ErrorPageProps {
  icon: string;
  title: string;
  subText?: string;
}

const ErrorPage = ({
  icon,
  title,
  subText = ''
}: ErrorPageProps): React.ReactElement<ErrorPageProps> => {
  return (
    <SFPaper className={styles.errorPage}>
      <div className={styles.icon}>
        <SFIcon icon={icon} size={64} />
      </div>

      <h1 className={styles.title}>{title}</h1>

      {subText.length > 0 && <h3 className={styles.subText}>{subText}</h3>}

      <p className={styles.contact}>
        <span>Would you like to contact us? Click on the link below.</span>
        <br />
        <SFLink
          color="primary"
          sfSize="medium"
          href="https://smartforce.atlassian.net/servicedesk/customer/portals"
          target="_blank"
          rel="noreferrer"
        >
          SmartForce® Support
        </SFLink>
      </p>
    </SFPaper>
  );
};

export default ErrorPage;
