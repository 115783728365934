import { ViewerData } from '../Models';

export interface AppServiceProps {
  getViewerData: (token: string) => Promise<ViewerData>;
}

export default class AppService implements AppServiceProps {
  api_url: string = process.env.REACT_APP_VIEWER_API as string;

  public getViewerData(token: string): Promise<ViewerData> {
    return fetch(`${this.api_url}/viewer/view/${token}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }).then((resp: Response) => {
      if (resp.ok) {
        return resp.json().then((data: ViewerData) => data);
      } else {
        return Promise.reject({
          code: resp.status,
          text: resp.statusText
        });
      }
    });
  }
}
