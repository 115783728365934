import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';

//TODO research strict mode
ReactDOM.render(
  <Router>
    {/* <React.StrictMode> */}
    <Switch>
      <Route path="/:token" children={<App />} />
    </Switch>
    {/* </React.StrictMode> */}
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
