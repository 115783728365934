import React from 'react';
import styles from './SFNavContent.module.scss';
import { useRouteMatch } from 'react-router-dom';
import { Customer } from '../../../Models';
import { SFPaper, SFScrollable } from 'sfui';
import SFLogo from '../../SFLogo/SFLogo';
import SFNavContentLink from './SFNavContentLink/SFNavContentLink';
import { SFNavLink } from '../SFNavLink';

export interface SFNavContentProps {
  customer: Customer;
  isPanel: boolean;
  links: SFNavLink[];
  selected: SFNavLink;
  onLinkChange: (
    e: React.MouseEvent<HTMLAnchorElement>,
    link: SFNavLink
  ) => void;
}

const extLinks: SFNavLink[] = [
  {
    to: 'https://smartforce.app/#/documentation',
    label: 'User Guides',
    icon: 'Book-Settings'
  },
  {
    to: 'https://smartforce.atlassian.net/servicedesk/customer/portals',
    label: 'Support',
    icon: 'Question-Mark'
  }
];

const SFNavContent = ({
  customer,
  isPanel,
  links,
  selected,
  onLinkChange
}: SFNavContentProps): React.ReactElement<SFNavContentProps> => {
  const { url } = useRouteMatch();

  const settingsLink = {
    to: `${url}/settings`,
    label: 'Settings',
    icon: 'Preferences-1'
  };

  return (
    <SFPaper className={styles.sfNavContent}>
      {!isPanel && (
        <div className={styles.logo}>
          <SFLogo customer={customer} />
        </div>
      )}

      <div className={styles.content}>
        <SFScrollable
          className={styles.scrollable}
          containerClassName={styles.scrollableContainer}
        >
          <ul>
            {links.map((link: SFNavLink) => (
              <li key={`link-${link.label}`}>
                <SFNavContentLink
                  link={link}
                  selected={link.to === selected.to}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    onLinkChange(e, link)
                  }
                />
              </li>
            ))}
          </ul>
          <div className={styles.settings}>
            <ul>
              <li>
                <SFNavContentLink
                  link={settingsLink}
                  selected={settingsLink.to === selected.to}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    onLinkChange(e, settingsLink)
                  }
                />
              </li>
              <li>
                <SFNavContentLink link={extLinks[0]} external />
              </li>
              <li>
                <SFNavContentLink link={extLinks[1]} external />
              </li>
            </ul>

            <footer className={styles.brand}>
              <span>Powered by</span>
              <br />
              <span className={styles.semibold}>
                SmartForce Technologies, Inc.
              </span>
            </footer>
          </div>
        </SFScrollable>
      </div>
    </SFPaper>
  );
};

export default SFNavContent;
