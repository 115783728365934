import { jsPDF } from 'jspdf';
import { SFRed, SFGrey } from 'sfui';
import {
  Report,
  ReportFooter,
  ReportHeader,
  Section,
  Field,
  BodyHits,
  Hit,
  Attachment
} from '../../Models';
import {
  upperFirstChar,
  convertFieldToString,
  cloneObject,
  isFieldEmpty,
  isSafari,
  removeHTMLTags,
  isRichText,
  removeEmptyTags
} from '../../Helpers';
import { Roboto, RobotoMedium, RobotoBold } from './fonts';
import { front, back, missed } from './bodyhit';

// Converts px to mm
function pxToMM(px: number, dpi: number) {
  return (px * 25.4) / dpi;
}

interface FontStyle {
  weight: number;
  color?: string;
  size: number;
  lineHeight: number;
}

interface HitEvent {
  number: number;
  x: number;
  y: number;
  zone: string;
}

interface SideHitEvents {
  side: string;
  hits: HitEvent[];
}

interface TextDim {
  w: number;
  h: number;
}

/**
 * Removes empty fields
 */
const getFilteredReport = (report: Report): Report => {
  let newReport: Report = cloneObject(report);

  newReport.body = report.body.map((section: Section) => {
    let newSec: Section = cloneObject(section);
    if (Array.isArray(section.fields[0])) {
      let newFields: Field[][] = [];
      (section.fields as Field[][]).forEach((fields: Field[]) => {
        if (fields.length > 0) {
          newFields = [
            ...newFields,
            fields.filter((field: Field) => !isFieldEmpty(field))
          ];
        }
      });
      newSec.fields = newFields;
    } else {
      newSec.fields = (section.fields as Field[]).filter(
        (field: Field) => !isFieldEmpty(field)
      );
    }

    return newSec;
  });

  return newReport;
};

/**
 * Group hit events by side
 */
const getHitEventsGroupedBySide = (bodyHits: BodyHits): SideHitEvents[] => {
  let sides: { [id: string]: HitEvent[] } = {
    front: [],
    back: [],
    missed: []
  };

  bodyHits.forEach((hit: Hit, index: number) => {
    sides[hit.side].push({
      number: index,
      x: hit.x,
      y: hit.y,
      zone: hit.zone
    });
  });

  let eventSides: SideHitEvents[] = [];
  for (const key in sides) {
    if (sides[key].length > 0) {
      eventSides.push({
        side: key,
        hits: sides[key]
      });
    }
  }

  return eventSides;
};

export default class PdfService {
  private timezone: string | undefined;
  private doc: jsPDF;
  private isGenerated: boolean;
  private report: Report;
  private hiddenFrame?: HTMLIFrameElement;

  private fontName: string;
  private letterSpace: number;

  // Margins of the page
  private marginTop: number;
  private marginBottom: number;
  private marginLeft: number;
  private marginRight: number;

  //Page size
  private pageHeight: number;
  private pageWidth: number;

  private headerColumnWidth: number;

  private sectionFieldMarginLeft: number;
  private sectionFieldLabelWidth: number;
  private sectionFieldValueWidth: number;
  private sectionFieldValueStart: number;

  private subsectionHRHeight: number;

  // Current page y to print
  private pageY: number;

  private contentBottom: number;

  private imgSideWidth: number = 0;
  private imgMissedWidth: number = 0;
  private imgSideHeight: number = 0;
  private imgMissedHeight: number = 0;
  private bodyHitsImgHeight: number = 0;
  private circleRadio: number = 0;

  private fieldLabelFontStyle: FontStyle = {
    weight: 700,
    color: SFGrey[900],
    size: 11,
    lineHeight: 16
  };

  private fieldValueFontStyle: FontStyle = {
    weight: 400,
    color: SFGrey[900],
    size: 11,
    lineHeight: 16
  };

  constructor(report: Report, timezone?: string) {
    this.timezone = timezone;
    this.doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'letter' });
    this.isGenerated = false;
    this.fontName = 'Roboto';

    // Letter space of 3px
    this.letterSpace = this.pxToMM(3);

    this.pageY = 0;
    this.pageWidth = this.doc.internal.pageSize.getWidth();
    this.pageHeight = this.doc.internal.pageSize.getHeight();

    //Vertical and horizontal margins
    this.marginTop = this.pxToMM(24);
    this.marginBottom = this.pxToMM(24);
    this.marginLeft = this.pxToMM(48);
    this.marginRight = this.pageWidth - this.pxToMM(48);

    // Header column width of 242px
    this.headerColumnWidth = 242;

    //Margins of each section field
    this.sectionFieldMarginLeft = this.marginLeft + this.pxToMM(32);
    this.sectionFieldLabelWidth = this.pxToMM(142);
    this.sectionFieldValueWidth = this.pxToMM(310);
    this.sectionFieldValueStart = this.pxToMM(254);

    //From here starts the footer
    this.contentBottom = this.pageHeight - this.pxToMM(96);

    // Top margin + hr size + bottom margin
    this.subsectionHRHeight = this.pxToMM(9) + this.pxToMM(1) + this.pxToMM(9);

    this.imgMissedWidth = 20;
    //Width of column minus width of missed image
    this.imgSideWidth = (this.sectionFieldValueWidth - this.imgMissedWidth) / 2;

    //Mantain img ratio 1.81 (height / width)
    this.imgSideHeight = this.imgSideWidth * 1.81;
    this.imgMissedHeight = 20;

    //Size of image + hr size + top and bottom margins
    this.bodyHitsImgHeight =
      this.imgSideHeight + this.pxToMM(2) + this.pxToMM(24);

    //12px diameter
    this.circleRadio = this.pxToMM(6);

    // Add fonts
    this.doc.addFileToVFS('Roboto-normal.ttf', Roboto);
    this.doc.addFileToVFS('Roboto-medium.ttf', RobotoMedium);
    this.doc.addFileToVFS('Roboto-bold.ttf', RobotoBold);
    this.doc.addFont('Roboto-normal.ttf', 'Roboto', 'normal', 400);
    this.doc.addFont('Roboto-medium.ttf', 'Roboto', 'normal', 500);
    this.doc.addFont('Roboto-bold.ttf', 'Roboto', 'normal', 700);

    // Remove empty data
    this.report = getFilteredReport(report);
  }

  private getFileName() {
    const customer = this.report.header.customer.full_name
      .toLowerCase()
      .replace(/\s/g, '_');
    const type = this.report.type.toLowerCase().replace(/\s/g, '_');

    return `${customer}-${type}-${this.report.header.report_id}.pdf`;
  }

  /**
   * Download pdf file
   */
  public downloadPdf() {
    if (!this.isGenerated) {
      this.generateDocument();
    }

    this.doc.save(this.getFileName());
  }

  /**
   * Show print menu
   */
  public print() {
    if (!this.isGenerated) {
      this.generateDocument();
    }

    this.doc.autoPrint();

    if (!this.hiddenFrame) {
      this.hiddenFrame = document.createElement('iframe');
      this.hiddenFrame.style.position = 'fixed';
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      this.hiddenFrame.style.width = '1px';
      this.hiddenFrame.style.height = '1px';
      this.hiddenFrame.style.opacity = '0.01';

      if (isSafari()) {
        // fallback in safari
        this.hiddenFrame.onload = () => {
          setTimeout(() => {
            try {
              this.hiddenFrame?.contentWindow?.document.execCommand(
                'print',
                false,
                undefined
              );
            } catch (e) {
              this.hiddenFrame?.contentWindow?.print();
            }
          }, 100);
        };
      }

      document.body.appendChild(this.hiddenFrame);
    }

    this.hiddenFrame.src = this.doc.output('bloburl').toString();
  }

  /**
   * Generate jspdf document
   */
  private generateDocument() {
    this.isGenerated = true;

    // Remove automatically generated first blank page
    this.doc.deletePage(1);

    this.addPage(true);

    this.report.body.forEach((section: Section, index: number) => {
      this.addSection(section);

      //Add section hr if not last section
      if (index < this.report.body.length - 1) {
        this.addSectionHR();
      }
    });

    //Add footer numbers
    this.addPageNumbers();
  }

  /**
   * Convert px to mm with current dpi
   */
  private pxToMM(px: number): number {
    //Double px for 144dpi
    return pxToMM(px * 2, 144);
  }

  /**
   * Set current document font
   */
  private setFont(fontStyle: FontStyle) {
    this.doc.setFont(this.fontName, 'normal', fontStyle.weight);
    this.doc.setFontSize(fontStyle.size);
    fontStyle.color && this.doc.setTextColor(fontStyle.color as string);
  }

  /**
   * Get text height and width of text with a specific font style
   */
  private getTextDim(text: string, fontStyle: FontStyle): TextDim {
    this.doc.setFont(this.fontName, 'normal', fontStyle.weight);
    this.doc.setFontSize(fontStyle.size);

    let textDim = this.doc.getTextDimensions(text);
    let lineHeightSpace: number = fontStyle.lineHeight - fontStyle.size;
    return {
      w: textDim.w,
      h: textDim.h + this.pxToMM(lineHeightSpace)
    };
  }

  /**
   * Add section hr
   */
  private addSectionHR() {
    const height: number = this.pxToMM(24) + this.pxToMM(1) + this.pxToMM(18);

    if (this.pageY + height > this.contentBottom) {
      this.addPage();
    }

    //Section hr top margin
    this.pageY += this.pxToMM(24);

    this.doc.setLineWidth(this.pxToMM(1));
    this.doc.setDrawColor(SFGrey[100]);
    this.doc.line(this.marginLeft, this.pageY, this.marginRight, this.pageY);

    //Section hr bottom margin
    this.pageY += this.pxToMM(18);
  }

  /**
   * Add subsection hr
   */
  private addSubSectionHR() {
    const height: number = this.pxToMM(9) + this.pxToMM(1) + this.pxToMM(9);

    if (this.pageY + height > this.contentBottom) {
      this.addPage();
    }

    //Top margin
    this.pageY += this.pxToMM(9);

    this.doc.setLineWidth(this.pxToMM(1));
    this.doc.setDrawColor(SFGrey[100]);
    this.doc.line(
      this.sectionFieldMarginLeft,
      this.pageY,
      this.marginRight,
      this.pageY
    );

    //Bottom margin
    this.pageY += this.pxToMM(9);
  }

  /**
   * Add multiline text inside a block with fixed width
   * Return next y coordinate value
   */
  private addMultiText(
    text: string,
    x: number,
    y: number,
    width: number,
    fontStyle: FontStyle
  ): number {
    this.doc.setFont(this.fontName, 'normal', fontStyle.weight);
    this.doc.setFontSize(fontStyle.size);
    this.doc.setTextColor(fontStyle.color as string);

    const lines = this.doc.splitTextToSize(text, width);

    let textY: number = y;

    for (const line of lines) {
      let textDim = this.doc.getTextDimensions(line);

      let lineHeightSpace: number =
        this.pxToMM(fontStyle.lineHeight - fontStyle.size) / 2;

      textY += textDim.h + lineHeightSpace;
      this.doc.text(line, x, textY);
      textY += lineHeightSpace;
    }

    return textY;
  }

  /**
   * Add a simple text
   * Return next y coordinate value
   */
  private addText(
    text: string,
    x: number,
    y: number,
    fontStyle: FontStyle,
    isRight: boolean = false
  ): number {
    this.doc.setFont(this.fontName, 'normal', fontStyle.weight);
    this.doc.setFontSize(fontStyle.size);
    this.doc.setTextColor(fontStyle.color as string);

    let textY: number = y;
    let textDim = this.doc.getTextDimensions(text);

    let lineHeightSpace: number =
      this.pxToMM(fontStyle.lineHeight - fontStyle.size) / 2;

    // If align isRight, start to print in (x - text width)
    let textX: number = x;
    if (isRight) {
      textX = x - textDim.w;
    }

    textY += textDim.h + lineHeightSpace;
    this.doc.text(text, textX, textY);
    textY += lineHeightSpace;

    return textY;
  }

  /**
   * Add a new page
   */
  private addPage(isFirst: boolean = false) {
    this.doc.addPage();

    // Reset pageY
    this.pageY = 0;

    this.addHeader(this.report.type, this.report.header);

    //Content top margin
    this.pageY += this.pxToMM(21);

    // If first page, add report title
    if (isFirst) {
      this.addTitle(`${this.report.type} Report`);
    }

    this.addFooter(this.report.footer);
  }

  /**
   * Check if a new page is needed and add it
   */
  private checkNewPage() {
    if (Math.round(this.pageY) >= Math.round(this.contentBottom)) {
      this.addPage();
    }
  }

  /**
   * Add page numbers to all pages footer
   */
  private addPageNumbers() {
    const totalPages: number = (this.doc.internal as any).getNumberOfPages();

    const lineHeightSpace: number = (12 - 9) / 2;

    let pageNumberY: number = this.contentBottom;

    //Top margin
    pageNumberY += this.pxToMM(20);

    //Line height upper half space
    pageNumberY += this.pxToMM(lineHeightSpace);

    this.doc.setFontSize(9);
    this.doc.setTextColor(SFGrey[900]);
    this.doc.setFont(this.fontName, 'normal', 400);

    const widthSeparator = this.doc.getTextDimensions('| ').w;

    for (let pageIndex = 1; pageIndex <= totalPages; pageIndex++) {
      this.doc.setPage(pageIndex);

      const widthCurrent = this.doc.getTextDimensions(`${pageIndex} `).w;

      this.doc.setFont(this.fontName, 'normal', 500);
      this.doc.text(
        `${pageIndex} `,
        this.pageWidth / 2 - (widthCurrent + widthSeparator),
        pageNumberY
      );

      this.doc.setFont(this.fontName, 'normal', 400);
      this.doc.text('| ', this.pageWidth / 2 - widthSeparator, pageNumberY);

      this.doc.text(totalPages.toString(), this.pageWidth / 2, pageNumberY);
    }
  }

  /**
   * Add report title
   */
  private addTitle(title: string) {
    this.pageY = this.addText(title, this.marginLeft, this.pageY, {
      weight: 700,
      color: SFGrey[900],
      size: 21,
      lineHeight: 31
    });

    //Title bottom margin
    this.pageY += this.pxToMM(18);
  }

  /**
   * Add report section
   */
  private addSection(section: Section) {
    this.checkNewPage();

    //Section title
    this.pageY = this.addText(section.label, this.marginLeft, this.pageY, {
      weight: 700,
      color: SFGrey[900],
      size: 16,
      lineHeight: 24
    });

    this.checkNewPage();

    //Section title bottom margin
    this.pageY += this.pxToMM(16);

    this.checkNewPage();

    // Check if section is an array of array
    if (Array.isArray(section.fields[0])) {
      (section.fields as Field[][]).forEach(
        (fields: Field[], index: number) => {
          for (const field of fields as Field[]) {
            this.addSectionField(field);
          }

          if (index !== section.fields.length - 1) {
            if (this.pageY + this.subsectionHRHeight >= this.contentBottom) {
              this.addPage();
            }

            this.addSubSectionHR();
          }
        }
      );
    } else {
      for (const field of section.fields as Field[]) {
        this.addSectionField(field);
      }
    }
  }

  /**
   * Get height of one side hit events labels
   */
  private getSideHitEventsLabelsHeight(
    x: number,
    sideHitEvents: SideHitEvents
  ): number {
    let auxX: number = x;

    let height: number = this.getTextDim(upperFirstChar(sideHitEvents.side), {
      weight: 400,
      size: 10,
      lineHeight: 14
    }).h;

    //Bottom margin of side label
    height += this.pxToMM(2);

    sideHitEvents.hits.forEach((hit: HitEvent, index: number) => {
      const text: string = `${hit.number + 1}/${hit.zone.toUpperCase()}`;
      const textDim = this.getTextDim(text, {
        weight: 400,
        size: 11,
        lineHeight: 16
      });

      if (auxX + textDim.w + this.pxToMM(12) > this.marginRight) {
        height += textDim.h;
        //Bottom margin side events
        height += this.pxToMM(2);
        auxX = x;
      }

      if (index === sideHitEvents.hits.length - 1) {
        height += textDim.h;
        //Bottom margin side events
        height += this.pxToMM(2);
      }
    });

    //Side hit events bottom margin
    height += this.pxToMM(12);

    //Side hit events bottom hr size
    height += this.pxToMM(1);

    //hr bottom margin
    height += this.pxToMM(9);

    return height;
  }

  /**
   * Get height of hit events labels (all sides)
   */
  private getBodyHitsLabelsHeight(x: number, bodyHits: BodyHits): number {
    const sideHitEventss: SideHitEvents[] = getHitEventsGroupedBySide(bodyHits);

    let height: number = 0;

    for (const sideHitEvents of sideHitEventss) {
      height += this.getSideHitEventsLabelsHeight(x, sideHitEvents);
    }

    return height;
  }

  /**
   * Get height of hit events (labels + images)
   */
  private getBodyHitsHeight(field: Field): number {
    return (
      this.getBodyHitsLabelsHeight(
        this.sectionFieldValueStart,
        field.value as BodyHits
      ) + this.bodyHitsImgHeight
    );
  }

  /**
   * Get height of one attachment
   */
  private getAttachmentHeight(attachment: Attachment, isLast: boolean): number {
    const labelLineHeight: number = 14;
    const valueLineHeight: number = 16;
    let height: number = 0;

    if (attachment.description) {
      //Description label height
      height += this.pxToMM(labelLineHeight);

      //Description label botton margin
      height += this.pxToMM(2);

      const descriptionLines = this.doc.splitTextToSize(
        attachment.description,
        this.sectionFieldValueWidth
      );

      height += descriptionLines.length * this.pxToMM(valueLineHeight);

      //Description value bottom margin
      height += this.pxToMM(12);
    }

    //File label height
    height += this.pxToMM(labelLineHeight);

    //File label botton margin
    height += this.pxToMM(2);

    const nameLines = this.doc.splitTextToSize(
      attachment.name,
      this.sectionFieldValueWidth
    );

    height += nameLines.length * this.pxToMM(valueLineHeight);

    //File value bottom margin
    height += this.pxToMM(12);

    //If is not last attachment, has hr
    if (!isLast) {
      //hr top margin
      height += this.pxToMM(12);
      // hr size
      height += this.pxToMM(1);
      //hr bottom margin
      height += this.pxToMM(12);
    }

    return height;
  }

  /**
   * Get height of the field label column
   */
  private getFieldLabelHeight(label: string) {
    const labelLines = this.doc.splitTextToSize(
      label,
      this.sectionFieldValueWidth
    );

    return labelLines.length * this.pxToMM(this.fieldLabelFontStyle.lineHeight);
  }

  /**
   * Add simple text field value column
   */
  private addSectionFieldValue(value: string) {
    const fontStyle: FontStyle = this.fieldValueFontStyle;

    let lineHeightSpace: number =
      this.pxToMM(fontStyle.lineHeight - fontStyle.size) / 2;

    this.setFont(fontStyle);

    /***
     * WORKAROUND: all HTML tags were removed (Plain Text)
     * TODO: Create a new logic to support render HTML tags.
     */

    let lines;

    //Check if it's a rich text
    if (isRichText(value)) {
      lines = this.getLinesFromHtml(value);
    } else {
      lines = this.doc.splitTextToSize(value, this.sectionFieldValueWidth);
    }

    for (const line of lines) {
      let textDim = this.doc.getTextDimensions(line);

      if (this.pageY + this.pxToMM(fontStyle.lineHeight) > this.contentBottom) {
        this.addPage();
        this.setFont(fontStyle);
      }

      this.pageY += textDim.h + lineHeightSpace;
      this.doc.text(line, this.sectionFieldValueStart, this.pageY);
      this.pageY += lineHeightSpace;
    }
  }

  /**
   * Add simple text field row (label and value)
   */
  private addSimpleField(field: Field) {
    const labelY: number = this.addMultiText(
      field.label,
      this.sectionFieldMarginLeft,
      this.pageY,
      this.sectionFieldLabelWidth,
      this.fieldLabelFontStyle
    );

    this.addSectionFieldValue(convertFieldToString(field, this.timezone));

    const labelDim = this.getTextDim(field.label, this.fieldLabelFontStyle);
    const valueDim = this.getTextDim(
      convertFieldToString(field, this.timezone),
      this.fieldLabelFontStyle
    );

    //Check if label is bigger than value to set the y coordinate
    if (labelDim.w > valueDim.w) {
      this.pageY = labelY;
    }
  }

  /**
   * Add attachments field row
   */
  private addAttachmentsField(field: Field) {
    const attachments: Attachment[] = field.value as Attachment[];

    //Check if the first attachment exceeds the bottom margin
    //If so, create a new page
    if (
      this.pageY +
        this.getAttachmentHeight(attachments[0], attachments.length === 1) >
      this.contentBottom
    ) {
      this.addPage();
    }

    // Field label column
    this.addMultiText(
      field.label,
      this.sectionFieldMarginLeft,
      this.pageY,
      this.sectionFieldLabelWidth,
      this.fieldLabelFontStyle
    );

    // Add attachments
    attachments.forEach((attachment: Attachment, index: number) => {
      const attachmentHeight: number = this.getAttachmentHeight(
        attachment,
        index === attachments.length - 1
      );

      if (this.pageY + attachmentHeight > this.contentBottom) {
        this.addPage();
      }

      this.addAttachment(attachment);

      //If it's the last attachment, add hr
      if (index < attachments.length - 1) {
        //hr top margin
        this.pageY += this.pxToMM(9);

        //Side hit events bottom hr
        this.doc.setLineWidth(this.pxToMM(1));
        this.doc.setDrawColor(SFGrey[100]);
        this.doc.line(
          this.sectionFieldValueStart,
          this.pageY,
          this.marginRight,
          this.pageY
        );

        // hr size
        this.pageY += this.pxToMM(1);
        //hr bottom margin
        this.pageY += this.pxToMM(9);
      }
    });
  }

  /**
   * Add body hits field row
   */
  private addBodyHitsField(field: Field) {
    //Check if the body hits field exceeds the bottom margin
    //If so, create a new page
    if (this.pageY + this.getBodyHitsHeight(field) > this.contentBottom) {
      this.addPage();
    }

    // Field label column
    this.addMultiText(
      field.label,
      this.sectionFieldMarginLeft,
      this.pageY,
      this.sectionFieldLabelWidth,
      this.fieldLabelFontStyle
    );

    const value: BodyHits = field.value as BodyHits;
    const sideHitEventss: SideHitEvents[] = getHitEventsGroupedBySide(value);
    for (const sideHitEvents of sideHitEventss) {
      this.pageY = this.addSideHitEventsLabels(
        this.sectionFieldValueStart,
        this.pageY,
        sideHitEvents
      );
    }

    this.addBodyHits(
      this.sectionFieldValueStart,
      this.pageY,
      value as BodyHits
    );

    //Add height of img to y coordinate
    this.pageY += this.imgSideHeight;
  }

  /**
   * Add a section field row
   */
  private addSectionField(field: Field) {
    //Check if field label height exceeds bottom margin
    //If so, create new page
    if (
      this.pageY + this.getFieldLabelHeight(field.label) >
      this.contentBottom
    ) {
      this.addPage();
    }

    if (field.type === 'attachments') {
      this.addAttachmentsField(field);
    } else if (field.type === 'bodyhits') {
      this.addBodyHitsField(field);
    } else {
      this.addSimpleField(field);
    }

    this.pageY += this.pxToMM(9);
  }

  /**
   * Add page footer
   */
  private addFooter(footer: ReportFooter) {
    // Footer text at bottom of the page minus bottom margin
    const textY: number = this.pageHeight - this.marginBottom;
    // Footer hr above text and add text line height and top margin
    const lineY: number = textY - this.pxToMM(16) - this.pxToMM(12);

    this.doc.setLineWidth(this.pxToMM(1));
    this.doc.setDrawColor(SFRed[50]);
    this.doc.line(this.marginLeft, lineY, this.marginRight, lineY);

    this.doc.setFont('Roboto', 'normal', 400);
    this.doc.setFontSize(11);
    this.doc.setTextColor(SFRed[700]);
    this.doc.text(footer.unclassified, this.pageWidth / 2, textY, {
      align: 'center'
    });
  }

  /**
   * Add page header
   */
  private addHeader(reportType: string, header: ReportHeader) {
    this.pageY = this.marginTop;

    // Add unclassified label
    this.doc.setFont('Roboto', 'normal', 400);
    this.doc.setFontSize(11);
    this.doc.setTextColor(SFRed[700]);

    //Top and bottom space of text (line height - font size) / 2
    const lineHeightSpace: number = this.pxToMM((16 - 11) / 2);

    //Add top half of line height space of text
    this.pageY += lineHeightSpace;

    this.doc.text(header.unclassified, this.pageWidth / 2, this.pageY, {
      align: 'center'
    });

    //Add bottom half of line height space of text
    this.pageY += lineHeightSpace;

    //unclassified text + bottom margin
    this.pageY += this.pxToMM(9);

    // Add unclassified bottom line
    this.doc.setLineWidth(this.pxToMM(1));
    this.doc.setDrawColor(SFRed[50]);
    this.doc.line(this.marginLeft, this.pageY, this.marginRight, this.pageY);

    //Size of hr
    this.pageY += this.pxToMM(1);

    //bottom margin
    this.pageY += this.pxToMM(9);

    //Customer name
    const customerNameLineHeight: number = 20;

    let customerInfoY: number =
      this.pageY + this.pxToMM(customerNameLineHeight / 2);

    this.doc.setFont(this.fontName, 'normal', 700);
    this.doc.setFontSize(12);
    this.doc.setTextColor(SFGrey[900]);
    this.doc.text(
      header.customer.full_name.toUpperCase(),
      this.marginLeft,
      customerInfoY
    );

    //Customer name bottom margin
    customerInfoY += this.pxToMM(2);

    //Customer address
    customerInfoY = this.addMultiText(
      header.customer.address,
      this.marginLeft,
      customerInfoY,
      this.headerColumnWidth,
      {
        weight: 400,
        color: SFGrey[900],
        size: 7,
        lineHeight: 8
      }
    );

    // Report info
    let reportInfoY = this.pageY;

    const reportIDLineHeight: number = 12;

    reportInfoY += this.pxToMM(reportIDLineHeight / 2);

    //Report ID
    this.doc.setFont(this.fontName, 'normal', 500);
    this.doc.setFontSize(9);
    this.doc.setTextColor(SFGrey[900]);
    const reportIdTextDim = this.doc.getTextDimensions(header.report_id);
    this.doc.text(
      header.report_id,
      this.marginRight - reportIdTextDim.w,
      reportInfoY
    );

    //Report id label
    this.doc.setFont(this.fontName, 'normal', 400);
    this.doc.setFontSize(9);
    this.doc.setTextColor(SFGrey[600]);
    const reportIdLabelTextDim = this.doc.getTextDimensions(reportType);
    this.doc.text(
      reportType,
      this.marginRight -
        reportIdTextDim.w -
        this.letterSpace -
        reportIdLabelTextDim.w,
      reportInfoY
    );

    reportInfoY += this.pxToMM(3);

    //Check if full employee text exceeds header column width
    let employeeText: string = `Employee Completing Report ${
      header['author.name'] || ''
    }`;

    const employeeLines = this.doc.splitTextToSize(
      employeeText,
      this.headerColumnWidth
    );

    employeeLines.forEach((line: string, index: number) => {
      if (index === 0) {
        //If first line, remove 'Employee Completing Report' from text
        const text = line.substring(27, line.length);

        this.addText(
          text,
          this.marginRight,
          reportInfoY,
          {
            weight: 500,
            color: SFGrey[900],
            size: 9,
            lineHeight: 12
          },
          true
        );

        //Print from right margin after employee name (or first line if multitext)
        const employeeX: number =
          this.marginRight -
          this.doc.getTextDimensions(text).w -
          this.letterSpace;

        //Needs to be added in a different call because it has different font style
        reportInfoY = this.addText(
          'Employee Completing Report',
          employeeX,
          reportInfoY,
          {
            weight: 400,
            color: SFGrey[600],
            size: 9,

            lineHeight: 12
          },
          true
        );
      } else {
        //If multiline add rest of employee lines
        reportInfoY = this.addText(
          line,
          this.marginRight,
          reportInfoY,
          {
            weight: 700,
            color: SFGrey[900],
            size: 9,
            lineHeight: 12
          },
          true
        );
      }
    });

    // Use max of customer info and report info
    this.pageY = Math.max(customerInfoY, reportInfoY);

    // Header info bottom margin
    this.pageY += this.pxToMM(9);

    // Add bottom hr
    this.doc.setLineWidth(this.pxToMM(2));
    this.doc.setDrawColor(SFGrey[100]);
    this.doc.line(this.marginLeft, this.pageY, this.marginRight, this.pageY);
  }

  /**
   * Add hit events by side
   * Returns next y coordinate
   */
  private addSideHitEventsLabels(
    x: number,
    y: number,
    sideHitEvents: SideHitEvents
  ): number {
    let auxY: number = y;
    let auxX: number = x;

    //Events side label
    auxY = this.addText(upperFirstChar(sideHitEvents.side), x, auxY, {
      weight: 400,
      color: SFGrey[600],
      size: 10,
      lineHeight: 14
    });

    //Events side label margin bottom
    auxY += this.pxToMM(2);

    const eventValueFont: FontStyle = {
      weight: 400,
      color: SFGrey[900],
      size: 11,
      lineHeight: 16
    };

    const hitEventMarginRight: number = this.pxToMM(9);

    sideHitEvents.hits.forEach((hit: HitEvent, index: number) => {
      const textNumber: string = (hit.number + 1).toString();
      const textSide: string =
        hit.zone === 'missed' ? '' : `/${hit.zone.toUpperCase()}`;
      const text: string = textNumber + textSide;
      const textDim = this.getTextDim(text, eventValueFont);

      //If the event with his margin exceeds the right margin, move it down
      if (auxX + textDim.w + hitEventMarginRight > this.marginRight) {
        // Text height
        auxY += textDim.h;
        // Margin bottom
        auxY += this.pxToMM(2);
        auxX = x;
      }

      this.addText(text, auxX, auxY, eventValueFont);

      // Text width
      auxX += textDim.w;
      // Margin right
      auxX += hitEventMarginRight;

      if (index === sideHitEvents.hits.length - 1) {
        // Text height
        auxY += textDim.h;
        // Margin bottom
        auxY += this.pxToMM(2);
      }
    });

    //Side hit events bottom margin
    auxY += this.pxToMM(12);

    //Side hit events bottom hr
    this.doc.setLineWidth(this.pxToMM(1));
    this.doc.setDrawColor(SFGrey[100]);
    this.doc.line(x, auxY, this.marginRight, auxY);

    // hr size
    auxY += this.pxToMM(1);
    //hr bottom margin
    auxY += this.pxToMM(9);

    return auxY;
  }

  /**
   * Add hit circles with his numbers
   */
  private addHit(x: number, y: number, hit: Hit, hitNum: number) {
    const circleCenter = this.circleRadio / 2;
    const imgWidth: number =
      hit.zone === 'missed' ? this.imgMissedWidth : this.imgSideWidth;
    const imgHeight: number =
      hit.zone === 'missed' ? this.imgMissedHeight : this.imgSideHeight;

    this.doc.setDrawColor('#000');
    this.doc.setFillColor('#000');
    this.doc.setFontSize(10);
    this.doc.setTextColor('#000');

    this.doc.circle(
      x + (+hit.x * imgWidth) / 100,
      y + (+hit.y * imgHeight) / 100,
      this.circleRadio,
      'FD'
    );

    this.doc.setTextColor('#fff');

    let textX: number =
      x +
      (+hit.x * imgWidth) / 100 -
      (hitNum < 10 ? circleCenter : this.circleRadio);

    this.doc.text(
      hitNum.toString(),
      textX,
      y + (+hit.y * imgHeight) / 100 + circleCenter
    );
  }

  /**
   * Add hit events images
   */
  private addBodyHits(x: number, y: number, bodyHits: BodyHits) {
    const frontX: number = x;
    const missedX: number = frontX + this.imgSideWidth;
    const backX: number = missedX + this.imgMissedWidth;

    this.doc.addImage(
      front,
      'PNG',
      frontX,
      y,
      this.imgSideWidth,
      this.imgSideHeight
    );

    this.doc.addImage(
      missed,
      'PNG',
      missedX,
      y,
      this.imgMissedWidth,
      this.imgMissedHeight
    );

    this.doc.addImage(
      back,
      'PNG',
      backX,
      y,
      this.imgSideWidth,
      this.imgSideHeight
    );

    bodyHits.forEach((hit: Hit, index: number) => {
      let hitX: number = frontX;
      switch (hit.side) {
        case 'front':
          hitX = frontX;
          break;
        case 'missed':
          hitX = missedX;
          break;
        case 'back':
          hitX = backX;
          break;
      }

      this.addHit(hitX, y, hit, index + 1);
    });
  }

  private addAttachment(attachment: Attachment) {
    const labelFontStyle: FontStyle = {
      weight: 400,
      color: SFGrey[600],
      size: 10,
      lineHeight: 14
    };

    const valueFontStyle: FontStyle = {
      weight: 400,
      color: SFGrey[900],
      size: 11,
      lineHeight: 16
    };

    if (attachment.description) {
      //Description label
      this.pageY = this.addText(
        'Description',
        this.sectionFieldValueStart,
        this.pageY,
        labelFontStyle
      );

      //Description label botton margin
      this.pageY += this.pxToMM(2);

      //Description value
      this.pageY = this.addMultiText(
        attachment.description,
        this.sectionFieldValueStart,
        this.pageY,
        this.sectionFieldValueWidth,
        valueFontStyle
      );

      //Description value botton margin
      this.pageY += this.pxToMM(9);
    }

    //File label
    this.pageY = this.addText(
      'File',
      this.sectionFieldValueStart,
      this.pageY,
      labelFontStyle
    );

    //File label botton margin
    this.pageY += this.pxToMM(2);

    //File value
    this.pageY = this.addMultiText(
      attachment.name,
      this.sectionFieldValueStart,
      this.pageY,
      this.sectionFieldValueWidth,
      valueFontStyle
    );
  }

  private getLinesFromHtml(htmlString: string) {
    const blockElems: string[] = [
      'address',
      'article',
      'aside',
      'blockquote',
      'div',
      'footer',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'header',
      'hgroup',
      'hr',
      'li',
      'main',
      'ol',
      'p',
      'pre',
      'section',
      'table',
      'tfoot',
      'ul',
      'br'
    ];

    //Split by opening block elements tags (with optional style and class atributtes)
    const blockElemsRegex = new RegExp(
      blockElems
        .map((s: string) => `<${s}(?: (?:class|style)=".+?")?>`)
        .join('|')
    );

    //First remove empty tags (to avoid unnecesary line breaks)
    const lines: string[] = removeEmptyTags(htmlString)
      .split(blockElemsRegex)
      .map((s: string) =>
        this.doc.splitTextToSize(removeHTMLTags(s), this.sectionFieldValueWidth)
      )
      .reduce((a, b) => [...a, ...b]);

    let returnLines: string[] = [];

    //Remove empty lines from the start
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].length > 0) {
        returnLines = [...lines.splice(i)];
        break;
      } else {
      }
    }

    return returnLines;
  }
}
