import React from 'react';
import styles from './ThemeSvg.module.scss';
import { ReactComponent as DMTheme } from '../../../Images/dm-theme.svg';
import { ReactComponent as NMTheme } from '../../../Images/nm-theme.svg';

export interface ThemeSvgProps {
  type: 'dayMode' | 'nightMode';
}

const ThemeSvg = (props: ThemeSvgProps): React.ReactElement<ThemeSvgProps> => {
  return (
    <div className={`${styles.themeSvg} `}>
      {props.type === 'dayMode' ? <DMTheme /> : <NMTheme />}
    </div>
  );
};

export default ThemeSvg;
