import { SFThemeType } from 'sfui';

export interface ThemeTypeServiceProps {
  getThemeType: () => SFThemeType | undefined;
  applyThemeType: (theme: SFThemeType) => void;
  saveThemeType: (theme: SFThemeType) => void;
}

export default class ThemeTypeService implements ThemeTypeServiceProps {
  public getThemeType(): SFThemeType | undefined {
    const theme: string | null = localStorage.getItem(
      'Smartforce.Viewer.ThemeType'
    );

    return theme ? (theme as SFThemeType) : undefined;
  }

  public applyThemeType(themeType: SFThemeType) {
    const body = document.getElementsByTagName('body')[0];
    if (themeType === 'night') {
      body.classList.add('nightMode');
    } else {
      body.classList.remove('nightMode');
    }
  }

  public saveThemeType(themeType: SFThemeType) {
    localStorage.setItem('Smartforce.Viewer.ThemeType', themeType);
    this.applyThemeType(themeType);
  }
}
