import React from 'react';
import styles from './BodyMissed.module.scss';
import { HitData } from '../BodyHits';
import BodyHit from '../BodyHit/BodyHit';

export interface BodyMissedProps {
  hits: HitData[];
}

const SVG_WIDTH = 598.7;
const SVG_HEIGHT = 598.7;

const BodyMissed = (
  props: BodyMissedProps
): React.ReactElement<BodyMissedProps> => {
  return (
    <div className={styles.bodyMissed}>
      <div className={styles.missedContainer}>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 598.7 598.7"
          enableBackground="new 0 0 598.7 598.7;"
          xmlSpace="preserve"
          className={styles.svgGraph}
        >
          <g id="Layer_x0020_1">
            <g id="_2537641932032">
              <polygon
                id="missed"
                className={styles.st0}
                points="1.7,1.7 597,1.7 597,597 1.7,597"
              />
            </g>
            <polygon
              id="missed_0"
              className={styles.st1}
              points="405.4,399.9 399.9,405.4 299.3,304.8 198.8,405.4 193.2,399.9 293.8,299.3 193.2,198.8 
			198.8,193.2 299.3,293.8 399.9,193.2 405.4,198.8 304.8,299.3"
            />
          </g>

          {props.hits.map((hit: HitData) => (
            <BodyHit
              key={`hit-${hit.position}`}
              hit={hit}
              circleSize={51}
              fontSize={51}
              width={SVG_WIDTH}
              height={SVG_HEIGHT}
            />
          ))}
        </svg>
      </div>
    </div>
  );
};

export default BodyMissed;
