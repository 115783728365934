import React, { useEffect, useState } from 'react';
import styles from './App.module.scss';

import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
  useRouteMatch
} from 'react-router-dom';

import {
  SFThemeProvider,
  SFStylesProvider,
  SFPaper,
  SFTheme,
  createSFTheme,
  useSFMediaQuery,
  SFThemeType,
  HttpStatusCode
} from 'sfui';

import Viewer from './Viewer/Viewer';
import ErrorPage from './ErrorPage/ErrorPage';
import Loader from '../Components/Loader/Loader';
import SFTopBar from '../Components/SFTopBar/SFTopBar';
import SFNavPanel from '../Components/SFNavPanel/SFNavPanel';
import { SFNavLink } from '../Components/SFNavPanel/SFNavLink';
import Settings from '../Components/SFSettings/SFSettings';

import AppService from '../Services/AppService';
import ThemeTypeService from '../Services/ThemeTypeService';

import { ViewerData } from '../Models';
import { BIG_MEDIA_SCREEN } from '../Constants/Media';

interface AppParams {
  token: string;
}

const appService: AppService = new AppService();
const themeTypeService: ThemeTypeService = new ThemeTypeService();

const App = (): React.ReactElement<{}> => {
  const { url, path } = useRouteMatch();
  const params: AppParams = useParams();

  const links: SFNavLink[] = React.useMemo(() => {
    return [
      {
        to: `${url}/viewer`,
        label: 'Viewer',
        icon: 'Eye'
      }
    ];
  }, [url]);

  const prefersDarkMode: boolean = useSFMediaQuery(
    '(prefers-color-scheme: dark)'
  );
  const isBigScreen: boolean = useSFMediaQuery(BIG_MEDIA_SCREEN);
  const location = useLocation();

  const savedThemeType: SFThemeType | undefined =
    themeTypeService.getThemeType();

  const defaultThemeType: SFThemeType = savedThemeType
    ? savedThemeType
    : prefersDarkMode
    ? 'night'
    : 'day';

  const [themeType, setThemeType] = useState<SFThemeType>(defaultThemeType);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorIcon, setErrorIcon] = useState<string>('Announcement');
  const [errorTitle, setErrorTitle] = useState<string>(
    'Sorry, something went wrong.'
  );
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [viewerData, setViewerData] = useState<ViewerData>();
  const [isNavPanelOpen, setIsNavPanelOpen] = useState<boolean>(false);
  const [siteTitle, setSiteTitle] = useState<string>('Settings');

  const theme: SFTheme = createSFTheme(themeType);

  useEffect(() => {
    themeTypeService.applyThemeType(defaultThemeType);
    setThemeType(defaultThemeType);
  }, [defaultThemeType]);

  useEffect(() => {
    const getViewerData = async () => {
      try {
        const data = await appService.getViewerData(params.token);
        setViewerData(data);
        setIsLoading(false);
      } catch (e) {
        if (e.code && e.code === HttpStatusCode.UNAUTHORIZED) {
          setErrorIcon('Lock');
          setErrorTitle('Sorry...');
          setErrorMsg(
            'The page you are trying to reach is forbidden for some reason.'
          );
          setHasError(true);
        } else {
          setErrorIcon('Announcement');
          setErrorTitle('Sorry, something went wrong.');
          setErrorMsg('');
          setHasError(true);
        }
        setIsLoading(false);
        console.log('Error', e);
      }
    };

    getViewerData();
  }, [params.token]);

  useEffect(() => {
    const pathname: string = location.pathname.split('/')[1];
    const link: SFNavLink | undefined = links.find(
      (link: SFNavLink) => link.to === `/${pathname}`
    );
    if (link) {
      setSiteTitle(link.label);
    }
  }, [links, location.pathname]);

  const onThemeChange = (type: SFThemeType) => {
    setThemeType(type);
    themeTypeService.saveThemeType(type);
  };

  return (
    <SFThemeProvider theme={theme}>
      <SFStylesProvider injectFirst>
        <SFPaper className={styles.container}>
          {isLoading && <Loader />}
          {!isLoading && hasError && (
            <ErrorPage icon={errorIcon} title={errorTitle} subText={errorMsg} />
          )}
          {!isLoading && !hasError && viewerData && (
            <div className={styles.app}>
              <SFNavPanel
                className={styles.navPanel}
                customer={viewerData.customer}
                links={links}
                isBigScreen={isBigScreen}
                isOpen={isNavPanelOpen}
                onClose={() => setIsNavPanelOpen(false)}
                onLinkSelected={(link: SFNavLink) => setSiteTitle(link.label)}
              />

              <SFTopBar
                className={styles.topBar}
                customer={viewerData.customer}
                siteTitle={siteTitle}
                onMenuButtonClick={() => setIsNavPanelOpen(true)}
              />

              <div className={styles.mainView}>
                <Switch>
                  <Route
                    path={`${path}/viewer`}
                    children={(props) => (
                      <Viewer
                        {...props}
                        reports={viewerData.reports}
                        extra={viewerData.extra}
                      />
                    )}
                  />

                  <Route
                    path={`${path}/settings`}
                    children={(props) => (
                      <Settings
                        {...props}
                        themeType={themeType}
                        onThemeChange={onThemeChange}
                        onLinkSelected={(link: SFNavLink) =>
                          setSiteTitle(link.label)
                        }
                      />
                    )}
                  />

                  {/* Default path */}
                  <Route
                    exact
                    path={path}
                    render={() => (
                      <Redirect
                        to={{
                          pathname: `${url}/viewer`,
                          search: location.search
                        }}
                      />
                    )}
                  />
                </Switch>
              </div>
            </div>
          )}
        </SFPaper>
      </SFStylesProvider>
    </SFThemeProvider>
  );
};

export default App;
