import React from 'react';
import styles from './SFSettingsMenu.module.scss';
import { Link } from 'react-router-dom';
import { SFNavLink } from '../../SFNavPanel/SFNavLink';

export interface SFSettingsMenuProps {
  links: SFNavLink[];
  selected: SFNavLink;
  onLinkChange: (link: SFNavLink) => void;
}

const SFSettingsMenu = ({
  links,
  selected,
  onLinkChange
}: SFSettingsMenuProps): React.ReactElement<SFSettingsMenuProps> => {
  return (
    <div className={styles.links}>
      <ul>
        {links.map((link: SFNavLink) => (
          <li key={`link-${link.label}`}>
            <Link
              className={link.to === selected.to ? styles.isSelected : ''}
              to={link.to}
              onClick={() => onLinkChange(link)}
            >
              <span>{link.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SFSettingsMenu;
