import React from 'react';
import styles from './Viewer.module.scss';
import { SFScrollable, SFScrollData } from 'sfui';
import { useLocation } from 'react-router-dom';
import { Report, Section } from '../../Models';
import PdfService from '../../Services/Pdf';
import ReportRender from './ReportRender/ReportRender';
import Toolbar from './Toolbar/Toolbar';
import { isValidTimeZone } from '../../Helpers';

function getTimezone(search: string): string | undefined {
  try {
    const timezoneParam = atob(new URLSearchParams(search).get('tz') || '');
    return isValidTimeZone(timezoneParam) ? timezoneParam : undefined;
  } catch (e) {
    return undefined;
  }
}

export interface ViewerProps {
  reports: Report[];
  extra: Section[];
}

const Viewer = (props: ViewerProps): React.ReactElement<ViewerProps> => {
  const { search } = useLocation();
  const [showDivider, setShowDivider] = React.useState<boolean>(false);
  const timezone = getTimezone(search);

  const pdfServices: PdfService[] = props.reports.map(
    (report: Report) => new PdfService(report, timezone)
  );

  const onScroll = (scrollData: SFScrollData) => {
    const show: boolean = scrollData.verticalScroll > 0;
    if (show !== showDivider) {
      setShowDivider(show);
    }
  };

  return (
    <div className={styles.viewer}>
      <Toolbar
        extra={props.extra}
        pdfServices={pdfServices}
        timezone={timezone}
      />

      <hr
        className={`${styles.divider} ${showDivider ? '' : styles.isHidden}`}
      />

      <div className={styles.reportContainer}>
        <SFScrollable
          containerClassName={styles.scrollableContainer}
          onScroll={onScroll}
        >
          {props.reports.map((report: Report) => (
            <ReportRender
              report={report}
              key={report.header.report_id}
              timezone={timezone}
            />
          ))}
        </SFScrollable>
      </div>
    </div>
  );
};

export default Viewer;
