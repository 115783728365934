import React, { useState } from 'react';
import styles from './SFSettings.module.scss';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { SFThemeType, useSFMediaQuery, SFPaper } from 'sfui';
import SFAppearance from './views/SFAppearance';
import SFSettingsMenu from './SFSettingsMenu/SFSettingsMenu';
import { SFNavLink } from '../SFNavPanel/SFNavLink';
import { PHONE_MEDIA_SCREEN } from '../../Constants/Media';

export interface SettingsProps {
  themeType: SFThemeType;
  onThemeChange: (theme: SFThemeType) => void;
  onLinkSelected: (link: SFNavLink) => void;
}

const Settings = ({
  themeType,
  onLinkSelected,
  onThemeChange
}: SettingsProps): React.ReactElement<SettingsProps> => {
  const { url, path } = useRouteMatch();

  const settingsLinks: SFNavLink[] = [
    {
      to: `${url}/settings/appearance`,
      label: 'Appearance'
    }
  ];

  const isPhoneScreen: boolean = useSFMediaQuery(PHONE_MEDIA_SCREEN);
  const [linkSelected, setLinkSelected] = useState<SFNavLink>(settingsLinks[0]);

  const onLinkChange = (link: SFNavLink) => {
    setLinkSelected(link);
    onLinkSelected(link);
  };

  return (
    <div className={styles.settings}>
      <h3 className={styles.title}>Settings</h3>
      {isPhoneScreen && (
        <SFPaper elevation={2} className={styles.settingsContainer}>
          <SFAppearance themeType={themeType} onChange={onThemeChange} />
        </SFPaper>
      )}

      {!isPhoneScreen && (
        <SFPaper elevation={2} className={styles.settingsContainer}>
          <SFSettingsMenu
            links={settingsLinks}
            selected={linkSelected}
            onLinkChange={(link: SFNavLink) => onLinkChange(link)}
          />

          <Switch>
            <Route
              path={`${path}/appearance`}
              children={() => (
                <SFAppearance themeType={themeType} onChange={onThemeChange} />
              )}
            />

            <Route
              exact
              path={path}
              render={() => <Redirect to={{ pathname: `${url}/appearance` }} />}
            />
          </Switch>
        </SFPaper>
      )}
    </div>
  );
};

export default Settings;
