import React from 'react';
import styles from './SFTopBar.module.scss';
import SFLogo from '../SFLogo/SFLogo';
import { Customer } from '../../Models';
import { SFIconButton } from 'sfui';

export interface SFTopBarProps {
  className?: string;
  customer: Customer;
  siteTitle: string;
  onMenuButtonClick: () => void;
}

const SFTopBar = ({
  className,
  onMenuButtonClick,
  siteTitle,
  customer
}: SFTopBarProps): React.ReactElement<SFTopBarProps> => {
  return (
    <div className={`${styles.sfTopBar} ${className || ''}`}>
      <div className={styles.topContent}>
        <SFIconButton sfSize='medium' sfIcon='Menu-1' className={styles.navPanelButton} onClick={() =>
          onMenuButtonClick()} />
        <h3 className={styles.siteTitle}>{siteTitle}</h3>
        <SFLogo className={styles.navLogo} customer={customer} />
      </div>
      <div className={styles.bottomContent}>
        <h3 className={styles.siteTitle}>{siteTitle}</h3>
      </div>
    </div>
  );
};

export default SFTopBar;
