import React from 'react';
import styles from './SFAppearance.module.scss';
import { SFRadio, SFThemeType } from 'sfui';
import ThemeSvg from '../ThemeSvg/ThemeSvg';

export interface SFAppearanceProps {
  themeType: SFThemeType;
  onChange: (theme: SFThemeType) => void;
}

const SFAppearance = ({
  themeType,
  onChange
}: SFAppearanceProps): React.ReactElement<SFAppearanceProps> => {
  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as SFThemeType);
  };

  return (
    <div className={styles.sfAppearance}>
      <h3 className={styles.title}>Appearance</h3>
      <div>
        <h4 className={styles.subtitle}>Themes</h4>
        <span className={styles.description}>
          Change the appearance of your system across all of your workspaces.
        </span>
      </div>

      <div className={styles.themes}>
        <div className={styles.theme}>
          <ThemeSvg type="dayMode" />
          <SFRadio
            label="Day Mode"
            name="radio-button-theme"
            value="day"
            checked={themeType === 'day'}
            onChange={onRadioChange}
          />
        </div>

        <div className={styles.theme}>
          <ThemeSvg type="nightMode" />
          <div className={styles.radio}>
            <SFRadio
              label="Night Mode"
              name="radio-button-theme"
              value="night"
              checked={themeType === 'night'}
              onChange={onRadioChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SFAppearance;
